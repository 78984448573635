
import * as msal from "msal";

const b2cPolicies = {
    //names: {
    //    signUpSignIn: "B2C_1A_signupsignin",
    //    editProfile: "B2C_1A_profileediting",
    //    passwordReset: "B2C_1A_passwordreset"
    //},
    authorities: {
        signUpSignIn: {
            authority: "https://kodakcustomer.b2clogin.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_signupsignin",
            embededAuthority: "https://kodakcustomer.b2clogin.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_signupsignin_EMBEDED_DEV",
            customDomainAuthority: "https://login.georgepig.net/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_signupsignin_EMBEDED_DEV",
        },
        editProfile: {
            authority: "https://kodakcustomer.b2clogin.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_profileediting",
            customDomainAuthority: "https://login.mytestap.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_profileediting",
        },
        passwordReset: {
            authority: "https://kodakcustomer.b2clogin.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_passwordreset",
            embededAuthority: "https://kodakcustomer.b2clogin.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_passwordreset_EMBEDED",
            customDomainAuthority: "https://login.georgepig.net/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_passwordreset",
        }
    },
    authorityDomain: ["kodakcustomer.b2clogin.com", "login.mytestap.com", "login.georgepig.net"]
};

const apiConfig = {
    b2cScopes: ["https://kodakcustomer.onmicrosoft.com/kodak-ondemand-api/write",
        "https://kodakcustomer.onmicrosoft.com/kodak-ondemand-api/delete",
        "https://kodakcustomer.onmicrosoft.com/kodak-ondemand-api/read"],
};

//const msalConfig: msal.Configuration = {
//    auth: {
//        clientId: "4bfe9b80-d569-4815-90aa-0f467357efb4",
//        //clientId: "40a8a438-1f11-4603-bef2-d788e125758e", // This is the ONLY mandatory field; everything else is optional.
//        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
//        knownAuthorities: [...b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
//        //redirectUri: "http://localhost:8080", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
//    },
//    cache: {
//        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
//        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
//    },
//};

const msalConfigEmbeded: msal.Configuration = {
    auth: {
        //clientId: "4bfe9b80-d569-4815-90aa-0f467357efb4",
        clientId: "40a8a438-1f11-4603-bef2-d788e125758e", // This is the ONLY mandatory field; everything else is optional.
        //authority: b2cPolicies.authorities.signUpSignIn.embededAuthority, // Choose sign-up/sign-in user-flow as your default.
        authority: b2cPolicies.authorities.signUpSignIn.customDomainAuthority,
        knownAuthorities: [...b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
        //redirectUri: "http://localhost:8080", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
        cacheLocation: "localStorage", // "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
};

const loginRequest: msal.AuthenticationParameters = {
    scopes: ["openid", ...apiConfig.b2cScopes]
};

const redirectRequest: msal.AuthenticationParameters = {
    scopes: ["openid", ...apiConfig.b2cScopes],
    //redirectUri: "http://localhost:8080"
    //redirectStartPage: ""

};

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const tokenRequest = {
    scopes: [...apiConfig.b2cScopes],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

class AzureAuth {
    private app!: msal.UserAgentApplication;
    //private embededApp!: msal.UserAgentApplication;


    private constructor() {
        //this.app = new msal.UserAgentApplication(msalConfig);
        this.app = new msal.UserAgentApplication(msalConfigEmbeded);

        //this.embededApp = new msal.UserAgentApplication(msalConfigEmbeded);

        this.app.handleRedirectCallback(this.redirectSucceeded, this.redirectFailed);
    }

    private static inst: AzureAuth;
    public static get Instance(): AzureAuth {
        return this.inst || (this.inst = new this());
    }

    private redirectSucceeded(respnose: any) {
        console.log("azure: redirectSucceeded", respnose);
    }
    private redirectFailed(error: any) {
        //console.log("azure: redirectFailed", error);
        console.log("azure: error.message", error.message);
        console.log("azure: error.errorCode", error.errorCode);

        console.log("azure: window.location.origin", window.location.origin);
        console.log("azure: document.referrer", document.referrer);
        let iframe = window.top !== window;
        console.log("azure: script running in iframe", iframe);

        let inst = AzureAuth.Instance;

        if (error.message && (error.message as string).indexOf("AADB2C90091") > -1) {
            console.log("azure: The user has cancelled entering self-asserted information.");
        }

        if (error.message && (error.message as string).indexOf("AADB2C90118") > -1) {
            console.log("azure: user is trying to reset password");
            //if (iframe) {
            //    console.log("azure: navigating to password-reset flow in iframe");
            //}

            //inst.app.authority = iframe
            //    ? b2cPolicies.authorities.passwordReset.embededAuthority
            //    : b2cPolicies.authorities.passwordReset.authority;
            //inst.app.loginRedirect(redirectRequest);

            if (iframe) {
                // in iframe
                // send passwordreset request to parent window
                console.log("azure: send message passwordreset to top");
                window.parent.postMessage('passwordreset', '*');
            } else {
                inst.app.authority = b2cPolicies.authorities.passwordReset.customDomainAuthority;
                inst.app.loginRedirect(redirectRequest);
            }

            return Promise.resolve(undefined);
        }

        if (!iframe &&
            ((error.message && (error.message as string).indexOf("Login_In_Progress") > -1) ||
                (error.errorCode && (error.errorCode as string).toLowerCase() === 'login_progress_error'))
        ) {
            console.log("azure: inst.app.authority = ", inst.app.authority);
            if (inst.app.authority.toLowerCase().indexOf(b2cPolicies.authorities.passwordReset.customDomainAuthority.toLowerCase()) > -1) {
                // try to reset password again
                console.log("azure: trying reset password again");
                setTimeout(() => {
                    inst.app.loginRedirect(redirectRequest);
                }, 1000);

            }

        }
    }

    public signIn() {
        return this.app.loginPopup(loginRequest)
            .then(response => {
                console.log("azure: signIn", response);
                return response.account;
            })
            .catch(error => {
                if (error.errorCode === 'user_cancelled') {
                    // user cancelled the login flow
                    console.log(error.message);
                }
                else {
                    console.error("azure: signIn", error);
                }

                throw error;
            });
    }

    public signOut() {
        if (this.app) {
            this.app.logout();
        }
    }

    public signInRedirect(redirectUri?: string) {
        this.app.loginRedirect(
            redirectUri ? Object.assign(redirectRequest, { redirectUri: redirectUri }) : redirectRequest);
    }

    public requestPasswordReset() {
        this.app.logout();
        this.app.authority = b2cPolicies.authorities.passwordReset.customDomainAuthority;
        console.log("azure: trying to reset password...");
        this.app.loginRedirect(redirectRequest);
    }

    //public signOutEmbed() {
    //    return this.embededApp.logout();
    //}

    public getCachedAccount() {
        let account = this.app.getAccount();

        return account;
    }

    public getUser() {
        let _this = this;
        let user = this.app.getAccount();

        if (!user) {
            return Promise.resolve(undefined);
        } else {
            // existing cache account (in localStorage) is found
            console.log("azure: existing account found: ", user)
            let idToken = user.idToken;
            let request = JSON.parse(JSON.stringify(tokenRequest));
            // check if seesion is expired
            if (idToken.exp) {
                let exp = new Date(Number.parseInt(idToken.exp) * 1000);
                let now = new Date();

                if (now > exp) {
                    // re-authentication required
                    request.forceRefresh = true;
                } 
            }

            return this.app.acquireTokenSilent(request)
                .then(response => {
                    let jwt = response.accessToken;
                    let cred = _this.parseJwt(jwt);
                    console.log('refreshed claims', cred.claims);
                    console.log('cached claims', response.idTokenClaims);

                    let account = {} as msal.Account;
                    account.idTokenClaims = cred.claims;
                    account.accountIdentifier = cred.claims.oid;
                    //account.userName = `${cred.claims.given_name} ${cred.claims.family_name}`;
                    account.name = cred.claims.name;

                    if (account.accountIdentifier != user.accountIdentifier) {
                        // a new user than cached account is logging in
                        let hint = cred.claims.email || cred.claims.otherMails[0];
                        let lRequest: msal.AuthenticationParameters = {
                            scopes: apiConfig.b2cScopes,
                            loginHint: hint,
                            //extraQueryParameters: {
                            //    "ui_locales": _this.Locale
                            //},
                            forceRefresh: true,
                        };
                        return _this.app.ssoSilent(lRequest)
                            .then(acc => {
                                console.log('acc.idToken', acc.idToken);
                                return _this.getCachedAccount();
                            })
                            .catch(err => {
                                console.log('acc.idToken err', err);
                                return Promise.resolve(undefined);
                            });
                    } else {
                        console.log('account', account);
                        return account;
                    }

                })
                .catch(error => {
                    return Promise.resolve(undefined);
                });
            
        }
    }



    private parseJwt(jwt: string) {
        let parts = jwt.split(".");
        let jws = {
            protected: parts[0],
            payload: parts[1],
            signature: parts[2],
        } as any;
        jws.header = this.urlBase64ToJson(jws.protected);
        jws.claims = this.urlBase64ToJson(jws.payload);

        return jws;
    }

    private urlBase64ToJson(u64: any) {
        let b64 = this.urlBase64ToBase64(u64);
        let str = atob(b64);
        return JSON.parse(str);
    }

    private urlBase64ToBase64(str: any) {
        let r = str % 4;
        if (2 === r) {
            str += "==";
        } else if (3 === r) {
            str += "=";
        }
        return str.replace(/-/g, "+").replace(/_/g, "/");
    }
}

const MSALObj = AzureAuth.Instance;

export default MSALObj;