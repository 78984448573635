
import * as msal from "@azure/msal-browser";

const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1A_signupsignin",
        editProfile: "B2C_1A_profileediting",
        passwordReset: "B2C_1A_passwordreset"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://kodakcustomer.b2clogin.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_signupsignin",
            embededAuthority: "https://kodakcustomer.b2clogin.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_signupsignin_EMBEDED",
            authorityRedirect: "https://kodakcustomer.b2clogin.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_signupsignin_redirect",
            authorityRedirect2: "https://logindev.mytestap.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_signupsignin_redirect",
        },
        editProfile: {
            authority: "https://kodakcustomer.b2clogin.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_profileediting"
        },
        passwordReset: {
            authority: "https://kodakcustomer.b2clogin.com/tfp/kodakcustomer.onmicrosoft.com/B2C_1A_passwordreset"
        }
    },
    authorityDomain: "kodakcustomer.b2clogin.com"
};

const apiConfig = {
    b2cScopes: ["https://kodakcustomer.onmicrosoft.com/kodak-ondemand-api/write",
        "https://kodakcustomer.onmicrosoft.com/kodak-ondemand-api/delete",
        "https://kodakcustomer.onmicrosoft.com/kodak-ondemand-api/read"],
};

//const msalConfig: msal.Configuration = {
//    auth: {
//        clientId: "4bfe9b80-d569-4815-90aa-0f467357efb4",
//        //clientId: "40a8a438-1f11-4603-bef2-d788e125758e", // This is the ONLY mandatory field; everything else is optional.
//        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
//        knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
//        //redirectUri: "http://localhost:8080", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
//    },
//    cache: {
//        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
//        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
//    },
//};

const msalConfigEmbeded: msal.Configuration = {
    auth: {
        clientId: "4bfe9b80-d569-4815-90aa-0f467357efb4",
        //clientId: "40a8a438-1f11-4603-bef2-d788e125758e", // This is the ONLY mandatory field; everything else is optional.
        authority: b2cPolicies.authorities.signUpSignIn.authorityRedirect2, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [b2cPolicies.authorityDomain, "logindev.mytestap.com"], // You must identify your tenant's domain as a known authority.
        //redirectUri: "http://localhost:8080", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
        cacheLocation: "localStorage", // "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
};

const loginRequest: msal.PopupRequest = {
    scopes: ["openid", ...apiConfig.b2cScopes]
};

const redirectRequest: msal.RedirectRequest = {
    scopes: ["openid", ...apiConfig.b2cScopes],
    //redirectUri: "http://localhost:8080"
    //redirectStartPage: ""

};

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const tokenRequest = {
    scopes: [...apiConfig.b2cScopes],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

class AzureAuth {
    private app!: msal.PublicClientApplication;

    public responseExternalCallback!: Function;

    private constructor() {
        this.app = new msal.PublicClientApplication(msalConfigEmbeded);

        let _this = this;
        this.app.handleRedirectPromise()
            .then(response => {
                if (response) {
                    console.log("azure-browser.handleRedirectPromise", response);
                    _this.handleResponse(response);
                }
            })
            .catch(error => {
                console.log("azure-browser.handleRedirectPromise error:", error);
            });
    }

    private handleResponse(response?: msal.AuthenticationResult) {
        if (response) {
            console.log("responseExternalCallback", this.responseExternalCallback);
            if (this.responseExternalCallback) {
                //this.responseExternalCallback.call(response);
                this.responseExternalCallback(response);
            }
        }
    }

    private static inst: AzureAuth;
    public static get Instance(): AzureAuth {
        return this.inst || (this.inst = new this());
    }

    public signIn() {
        return this.app.loginPopup(loginRequest)
            .then(response => {
                console.log("signIn", response);
                return response.account;
            })
            .catch(error => {
                console.error("signIn", error);
                throw error;
            });
    }

    public signOut() {
        const logoutRequest = {
            postLogoutRedirectUri: msalConfigEmbeded.auth.redirectUri,
            mainWindowRedirectUri: msalConfigEmbeded.auth.redirectUri
        };

        //return this.app.logout(logoutRequest);
        return this.app.logout();
        //return this.app.logoutPopup();
    }

    public signInRedirect(redirectUri?: string) {
        if (redirectUri) {
            redirectRequest.redirectUri = redirectUri;
        }

        return this.app.loginRedirect(redirectRequest)
            .then(response => {
                console.log("azure-browser: signInRedirect", response);
            })
            .catch(error => {
                if (error.errorCode === 'interaction_in_progress') {
                    console.log("azure-browser: signInRedirect error", error.errorCode);
                } else {
                    console.error("azure-browser: signInRedirect error", error);
                    throw error;
                }
            });
    }

    public signOutRedirect() {
        const logoutRequest = {
            postLogoutRedirectUri: msalConfigEmbeded.auth.redirectUri,
            mainWindowRedirectUri: msalConfigEmbeded.auth.redirectUri
        };

        return this.app.logoutRedirect();
    }


    public getUser() {
        //let account = this.app.getActiveAccount();

        let accounts = this.app.getAllAccounts();

        let account = accounts?.length > 0 ? accounts[0] : undefined;

        if (account && account.idTokenClaims && account.idTokenClaims.exp) {

            // check if seesion is expired
            let now = new Date();
            if (now > new Date(account.idTokenClaims.exp * 1000)) {
                // re-authentication required
                // return undefined;
            } else {
                return account;
            }
        }

        return undefined;
    }
}

const Azure = AzureAuth.Instance;

export default Azure;