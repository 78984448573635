import Vue from 'vue';
import App from './App.vue';
//import Router from 'vue-router';
//import router from './router/index';

Vue.config.productionTip = true;
//Vue.use(Router);

new Vue({
    //router,
    render: h => h(App)
}).$mount('#app');
