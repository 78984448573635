


























































































































import { Component, Vue } from 'vue-property-decorator';
import Home from './components/Home.vue';
import msal from '@/azure/azure';
import msalBrowser from '@/azure/azure-browser'

@Component({
    components: {
        Home
    }
})
export default class App extends Vue {
    private user!: any;
    private authenticated: boolean = false;
    private errorInfo!: any;
    private message!: string | undefined;

    private userBrowser!: any;
    private authenticatedBrowser: boolean = false;
    private errorBrowser!: any;
    private messageBrowser!: string | undefined;

    private loadIframe: boolean = false;

    public data() {

        return {
            user: this.user,
            authenticated: this.authenticated,
            errorInfo: this.errorInfo,
            message: this.message,

            userBrowser: this.userBrowser,
            authenticatedBrowser: this.authenticatedBrowser,
            errorBrowser: this.errorBrowser,
            messageBrowser: this.messageBrowser,

            loadIframe: this.loadIframe,

        }
    }


    private setState(user?: any) {
        if (user) {
            this.authenticated = true;
            this.user = user;
            this.message = `Hello ${user.name}`;
        }
    }

    mounted() {
        let _this = this;

        window.addEventListener('message', event => {
            console.log('top: onmessage', event.data);
            if (event.data === 'passwordreset') {
                console.log('top: passwordreset request received from iframe');
                msal.requestPasswordReset();
            }
        });

        if (window.top !== window) {
            // in iframe

            console.log('iframe: ', 'Main App Mounted');
            console.log('iframe: ', "window.top == window", window.top == window);
            console.log('iframe: ', "window.parent == window", window.parent == window);

            if (window.location.href) {
                // redirect back from auth endpoint
                // has been authenticated

            } else {
                // redirect to log-in page

            }
        } else {
            msal.getUser()
                .then(user => {
                    if (user) {
                        console.log("top: current user: ", user)
                        _this.setState(user);
                    }
                })
                .catch(err => { });
            

            msalBrowser.responseExternalCallback = this.handleMsalBrowserReidrectResponse;
            this.userBrowser = msalBrowser.getUser();
            if (this.userBrowser) {
                console.log("top: current userBrowser: ", this.userBrowser)
                this.authenticatedBrowser = true;
            }

            console.log('top: ', 'Main App Mounted');
            console.log('top: ', "window.top == window", window.top == window);
            console.log('top: ', "window.parent == window", window.parent == window);
        }

        //let iframe = document.getElementById('loginFrame') as HTMLIFrameElement;
        //console.log(iframe);
        //if (iframe) {
        //    iframe.onload = function () {

        //        console.log("iframe loaded");
        //        var iframeBody = (iframe.contentDocument as Document)?.body;
        //        if (iframeBody) {
        //            console.log("iframeBody");
        //            //window.top.location.reload();
        //        }
        //    }
        //}
    }

    ///+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    /// msal core - deprecated
    ///+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    public signInPopup() {
        msal.signIn()
            .then(account => {
                this.authenticated = true;
                this.user = account;
                this.message = `Hello ${account?.name}`;
            })
            .catch(err => {
                this.authenticated = false;
                this.errorInfo = err;
            });
    }

    public signInRedirect(redirectUri?: string) {
        msal.signInRedirect();
    }

    public signOut() {
        msal.signOut();

        this.authenticated = false;
        this.user = undefined;
        this.message = undefined;
    }

    ///+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    /// msal-browser
    ///+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    public signInPopupBrowser() {
        msalBrowser.signIn()
            .then(account => {
                console.log("msalBrowser.signIn");

                this.authenticatedBrowser = true;
                this.userBrowser = account;
                this.messageBrowser = `Hello ${account?.name}`;

                let accounts = msalBrowser.getUser();
            })
            .catch(err => {
                console.log("msalBrowser.signIn", err);
                this.authenticatedBrowser = false;
                this.errorBrowser = err;
            });
    }

    public signOutBrowser() {
        msalBrowser.signOut()
            .then(s => {
                console.log("msalBrowser.signOut");
                this.authenticatedBrowser = false;
                this.userBrowser = undefined;
                this.messageBrowser = undefined;
            })
            .catch();
    }

    public signInRedirectBrowser() {
        msalBrowser.signInRedirect()
            .catch(err => {
                console.log("msalBrowser.signInRedirect", err);
                this.authenticatedBrowser = false;
                this.userBrowser = undefined;
                this.errorBrowser = err;
                throw err;
            });
    }

    public handleMsalBrowserReidrectResponse(response?: any) {
        console.log("App.handleMsalBrowserReidrectResponse", response);

        if (response) {
            this.userBrowser = response.account;
            this.authenticatedBrowser = !!this.userBrowser;
        }
    }

    public configIFrame(type?: any) {
        this.loadIframe = true;
        switch (type) {
            case 1:
                let container = document.getElementById('loginContainer') as HTMLDivElement;
                container.removeAttribute("hidden");

                let iframe = document.getElementById('loginFrame') as HTMLIFrameElement;
                console.log("iframe", iframe)
                if (iframe) {
                    iframe.setAttribute("src", "./login.html");
                }
                break;
            case 2:
                let container2 = document.getElementById('loginContainer2') as HTMLDivElement;
                container2.removeAttribute("hidden");

                let iframe2 = document.getElementById('loginFrame2') as HTMLIFrameElement;
                console.log("iframe2", iframe2)
                if (iframe2) {
                    iframe2.setAttribute("src", "/");
                }
                break;
            case 3:

                let container3 = document.getElementById('loginContainer3') as HTMLDivElement;
                container3.removeAttribute("hidden");

                let iframe3 = document.getElementById('loginFrame3') as HTMLIFrameElement;
                console.log("iframe3", iframe3)
                if (iframe3) {
                    iframe3.setAttribute("src", "./login2.html");
                }
                break;
            default:

                break;
        }
    }

}
